<template>
  <div class="container">
    <div class="header card">
      <h1>
        <span v-lang="'persons.title_index'"></span>
      </h1>
      <div class="actions">
        <router-link
          class="primary"
          v-if="$role(30274)"
          :to="{ name: 'PersonsForm' }"
          v-lang="'general.label_add'"
        ></router-link>
      </div>
    </div>

    <!-- <Loader2 v-if="!codesLoaded" /> -->
    <div class="card">
      <form action class="form" v-on:submit.prevent="searchFunction()">
        <div class="input m-0">
          <label for="number">
            <span v-lang="'victims.number'"></span>
          </label>
          <input
            class="m-0"
            v-model="searchModel.number"
            type="text"
            name="number"
            autocomplete="off"
          />
        </div>
        <div class="input m-0">
          <label for="fkInstitutionId">
            <span v-lang="'victims.institution'"></span>
          </label>
          <select
            v-model="searchModel.fkInstitutionId"
            name="fkInstitutionId"
            id="dropdownStatusCode"
            :disabled="disabledInstitution"
          >
            <option
              value="0"
              selected
              v-lang="'victims.placeholder_for_institution'"
            ></option>
            <option
              v-for="(obj, key) in codesModel.institutionCodes"
              :key="key"
              :value="obj.id"
              >{{ obj.name }}</option
            >
          </select>
        </div>
        <div class="input m-0">
          <label for="fkGenderCodeId">
            <span v-lang="'victims.gender'"></span>
          </label>
          <select
            v-model="searchModel.fkGenderCodeId"
            name="fkGenderCodeId"
            id="dropdownStatusCode"
          >
            <option
              value="0"
              selected
              v-lang="'victims.placeholder_for_gender'"
            ></option>
            <option
              v-for="(obj, key) in codesModel.genderCodes"
              :key="key"
              :value="obj.pkCodeId"
              >{{ obj.name }}</option
            >
          </select>
        </div>
        <div class="input m-0">
          <label for="fkCitizenshipCodeId">
            <span v-lang="'victims.citizenship'"></span>
          </label>
          <select
            v-model="searchModel.fkCitizenshipCodeId"
            name="fkCitizenshipCodeId"
            id="dropdownStatusCode"
          >
            <option
              value="0"
              selected
              v-lang="'victims.placeholder_for_citizenship'"
            ></option>
            <option
              v-for="(obj, key) in codesModel.countryCodes"
              :key="key"
              :value="obj.pkCodeId"
              >{{ obj.name }}</option
            >
          </select>
        </div>
        <div class="input m-0">
          <label for="date">
            <span v-lang="'victims.registration_date'"></span>
          </label>
          <date-picker
            name="registrationDate"
            v-model="searchModel.registrationDate"
            :inputClass="'input-date-picker'"
            :locale="lang"
            :initDate="false"
          ></date-picker>
        </div>
        <div class="input m-0">
          <label for="createdBy">
            <span v-lang="'victims.author'"></span>
          </label>
          <select
            v-model="searchModel.createdBy"
            name="createdBy"
            id="dropdownStatusCode"
          >
            <option
              value="0"
              selected
              v-lang="'victims.placeholder_for_author'"
            ></option>
            <option
              v-for="(obj, key) in codesModel.authorCodes"
              :key="key"
              :value="obj.id"
              >{{ obj.name }}</option
            >
          </select>
        </div>

        <div class="submit">
          <button
            type="button"
            class="warning"
            v-on:click="resetSearch($event)"
            v-lang="'general.button_reset'"
          ></button
          >&nbsp;
          <button class="primary" v-lang="'general.button_search'"></button>
        </div>
      </form>
    </div>

    <Loader2 v-if="!resultsLoaded" />
    <span v-else>
      <NoData v-if="!persons || persons.length == 0"></NoData>
      <div v-else class="scroll card table-container">
        <table class="table-hover">
          <thead>
            <tr :id="tableId">
              <th>#</th>
              <th v-on:click="sort('Number')" Number>
                {{ $lang("victims.number") }}
                <span></span>
              </th>
              <th v-on:click="sort('RegistrationDate')" RegistrationDate>
                {{ $lang("victims.registration_date") }}
                <span></span>
              </th>
              <th v-on:click="sort('Institution')" Institution>
                {{ $lang("victims.institution") }}
                <span></span>
              </th>
              <th v-on:click="sort('FkGenderCodeId')" FkGenderCodeId>
                {{ $lang("victims.gender") }}
                <span></span>
              </th>
              <th v-on:click="sort('FkCitizenshipCodeId')" FkCitizenshipCodeId>
                {{ $lang("victims.citizenship") }}
                <span></span>
              </th>
              <th v-on:click="sort('Author')" Author>
                {{ $lang("victims.author") }}
                <span></span>
              </th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(obj, key) in persons">
              <tr :key="key">
                <td>
                  {{
                    key + 1 + (searchModel.pageNum - 1) * searchModel.pageOffset
                  }}
                </td>
                <td>{{ obj.number }}</td>
                <td>
                  {{
                    obj.registrationDate != null
                      ? $moment(obj.registrationDate).format("DD.MM.YYYY.")
                      : ""
                  }}
                </td>
                <td>{{ obj.institution }}</td>
                <td>{{ obj.gender }}</td>
                <td>{{ obj.citizenship }}</td>
                <td>{{ obj.author }}</td>
                <td>
                  <a
                    v-if="$role(40273)"
                    class="default small"
                    v-on:click="personDetails(obj.pkFormPersonsId)"
                    v-lang="'general.label_details'"
                  ></a>
                  <a
                    v-if="$role(30275)"
                    class="primary small"
                    v-on:click="editPerson(obj.pkFormPersonsId)"
                    v-lang="'general.label_edit'"
                  ></a>
                  <a
                    v-if="$role(30276)"
                    class="error small"
                    v-on:click="deletePerson(obj)"
                    v-lang="'general.label_delete'"
                  ></a>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <div class="pagination-container">
        <paginate
          v-if="persons.length != 0 && pageCount > 1"
          :page-count="pageCount"
          :click-handler="changePage"
          v-model="searchModel.pageNum"
          :prev-text="this.$lang('general.previous')"
          :next-text="this.$lang('general.next_page')"
          :container-class="'paggination'"
        ></paginate>
      </div>
    </span>
  </div>
</template>

<script>
import Vue from "vue";
import { lang } from "@/global-functions";
import Loader2 from "@/components/General/Loader2";
import NoData from "@/components/General/NoData";
import Paginate from "vuejs-paginate";
import store from "./store";
import globalStore from "@/store";

export default Vue.extend({
  components: {
    Loader2,
    NoData,
    paginate: Paginate,
  },
  data() {
    return {
      codesLoaded: false,
      resultsLoaded: false,
      persons: [],
      pageCount: 0,
      searchModel: {
        number: this.$route.query.number != "" ? this.$route.query.number : "",
        fkInstitutionId:
          this.$route.query.fkInstitutionId > 0
            ? Number(this.$route.query.fkInstitutionId)
            : 0,
        fkGenderCodeId:
          this.$route.query.fkGenderCodeId > 0
            ? Number(this.$route.query.fkGenderCodeId)
            : 0,
        fkCitizenshipCodeId:
          this.$route.query.fkCitizenshipCodeId > 0
            ? Number(this.$route.query.fkCitizenshipCodeId)
            : 0,
        registrationDate:
          this.$route.query.registrationDate != null
            ? new Date(this.$route.query.registrationDate)
            : null,
        createdBy:
          this.$route.query.createdBy > 0
            ? Number(this.$route.query.createdBy)
            : 0,
        pageNum:
          this.$route.query.pageNum > 0 ? Number(this.$route.query.pageNum) : 1,
        pageOffset: 10,
        orderColumn:
          this.$route.query.orderColumn != ""
            ? this.$route.query.orderColumn
            : "",
        orderSort:
          this.$route.query.orderSort != "" ? this.$route.query.orderSort : "",
      },
      codesModel: {
        institutionCodes: [],
        institutionTypeCodes: [],
        genderCodes: [],
        yearCodes: [],
        countryCodes: [],
        authorCodes: [],
      },
      disabledInstitution: false,
      tableId: Math.random(),
      tr: document.getElementById(this.tableId),
      lang:
        globalStore.state.lang == "sr"
          ? "sr-cyrl"
          : globalStore.state.lang == "bs"
          ? "sr"
          : globalStore.state.lang == "hr"
          ? "hr"
          : "en",
    };
  },
  created() {
    this.getCodes();
    setTimeout(() => {
      this.search();
    }, 200);
  },
  mounted() {},
  watch: {
    "persons.length": function(n, o) {
      if (this.persons.length == 0 && this.searchModel.pageNum > 1) {
        this.searchModel.pageNum--;
        this.search();
      } else if (n < o) {
        this.search();
      }
    },
  },
  methods: {
    sort(columnName) {
      if (this.searchModel.orderColumn != columnName) {
        this.searchModel.orderColumn = columnName;
        this.searchModel.orderSort = "DESC";
      } else if (this.searchModel.orderSort == "DESC") {
        this.searchModel.orderSort = "ASC";
      } else {
        this.searchModel.orderSort = "DESC";
      }
      this.search();
    },
    setIcon() {
      var element = document.getElementById(this.tableId);
      if (element == null) return;
      var array = element.children;
      var item;
      for (let index = 0; index < array.length; index++) {
        if (
          array[index].hasAttribute(
            this.searchModel.orderColumn
              ? this.searchModel.orderColumn.replace(".", "-")
              : this.searchModel.orderColumn
          )
        ) {
          item = array[index];
        }
      }
      if (
        this.searchModel.orderColumn != "" &&
        this.searchModel.orderSort == "DESC"
      ) {
        item.classList.add("desc");
      } else if (this.searchModel.orderSort == "ASC") {
        item.classList.add("asc");
      }
    },
    initSearchModel() {
      this.searchModel.number = null;
      this.searchModel.fkInstitutionId = this.disabledInstitution
        ? this.searchModel.fkInstitutionId
        : 0;
      this.searchModel.fkGenderCodeId = 0;
      this.searchModel.fkCitizenshipCodeId = 0;
      this.searchModel.registrationDate = "";
      this.searchModel.createdBy = 0;
      this.searchModel.pageNum = 1;
      this.searchModel.pageOffset = 10;
      this.searchModel.orderColumn = "";
      this.searchModel.orderSort = "";
    },
    changePage: function(pageNum) {
      this.searchModel.pageNum = pageNum;
      this.search();
    },
    resetSearch() {
      this.initSearchModel();
      this.search();
    },
    searchFunction() {
      this.searchModel.pageNum = 1;
      this.search();
    },
    search() {
      this.$router.push({
        query: { ...this.$route.query, ...this.searchModel },
      });
      var $this = this;
      $this.resultsLoaded = false;
      store
        .dispatch("SEARCH_PERSON", this.searchModel)
        .then((response) => {
          $this.persons = response.data.list;

          response.data.list.length > 0
            ? ($this.pageCount = response.data.list[0].pageCount)
            : ($this.pageCount = 0);
          $this.resultsLoaded = true;
          setTimeout(() => {
            $this.setIcon();
          }, 50);
        })
        .catch((error) => {
          this.$store.commit("ERROR_MESSAGE", this.error_a);
        });
    },
    getCodes() {
      this.codesLoaded = false;
      store
        .dispatch("GET_CODES_DROPDOWN")
        .then((response) => {
          this.codesModel = response.data.model;
          if (response.data.model.institutionId != 5) {
            this.disabledInstitution = true;
            this.searchModel.fkInstitutionId =
              response.data.model.institutionId;
          }
          this.codesLoaded = true;
        })
        .catch((error) => {});
    },
    deletePerson(obj) {
      var $this = this;
      this.$removeToast();
      Vue.toasted.show(lang("persons.do_you_want_to_delete_this_person"), {
        theme: "toasted-primary",
        position: "top-center",
        duration: 5000,
        action: [
          {
            text: lang("general.label_yes"),
            onClick: (e, toastObject) => {
              store
                .dispatch("DELETE_PERSON", obj.pkFormPersonsId)
                .then((response) => {
                  $this.search();
                  $this.$success("persons.person_successfully_deleted");
                })
                .catch((error) => {
                  $this.$error("persons.person_delete_failed");
                });
              toastObject.goAway(0);
            },
          },
          {
            text: lang("general.label_no"),
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        ],
      });
    },
    editPerson(id) {
      this.$router.push({
        name: "PersonsForm",
        query: { pkFormPersonsId: id },
      });
    },
    personDetails(id) {
      this.$router.push({
        name: "PersonsDetails",
        params: { id: id },
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  .submit {
    grid-column: 1/4;
  }
  .fieldset {
    grid-column: 1/4;
    .fieldset-container {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
.form.small {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  .submit {
    grid-column: 1/3;
  }
  .fieldset {
    grid-column: 1/3;
    .fieldset-container {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 1fr 1fr;
    }
  }
}
</style>
